<template>
  <v-container
    id="regular-tables"

    tag="section"
  >
    <div>
      <base-material-card
        icon="mdi-account-group"
        class="px-5 py-3"
      >
        <v-data-table
          dense
          :headers="headers"
          :items="collaborators"
          :loading="loading"
          item-key="id"
          class="pt-8"
          loading-text="Loading... Please wait"
          :server-items-length="totalItems"
          :footer-props="{
            itemsPerPageText: $t('perPage'),
            itemsPerPageOptions: [5,20,40]
          }"
          :options="options"
          @pagination="handlePagination"
        />
      </base-material-card>
    </div>
  </v-container>
</template>

<script>
import UsersRepository from '@/api/UsersRepository'

export default {
  name: 'CollaboratorsIndex',

  data () {
    return {
      collaborators: [],
      search: '',
      headers: [
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('created'), value: 'created_at', align: 'end' },
      ],
      showCreateCollaborator: false,
      loading: true,
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 20,
      },
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
  },
  watch: {
    options: {
      handler (newValue, oldValue) {
        if (newValue.itemsPerPage !== oldValue.itemsPerPage) {
          this.fetchCollaboratos()
        }
        if (newValue.page !== oldValue.page) {
          this.fetchCollaboratos()
        }
      },
      deep: true,
    },
  },
  created () {
    this.fetchCollaborators()
  },
  methods: {
    fetchCollaborators () {
      this.loading = true
      UsersRepository.index({
        search: this.search,
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        with: 'userRole',
        entity: this.user.entity.id,
      })
        .then(({ data }) => {
          this.collaborators = data.data
          this.totalItems = data.meta.total
          this.loading = false
        })
        .catch(() => {
        })
    },
    handlePagination (options) {
      this.options = options
    },

  },
}
</script>

<style scoped>

</style>
